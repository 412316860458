<template>
  <div id="login">
    logging
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    };
  },
  created(){
    this.loginFun();
  },
  methods: {
    // 发帖账号登录
    loginFun(){

      this.oidcVarible.mgr.signinRedirect();
    },
  }
};
</script>
<style scoped>
#login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>